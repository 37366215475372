.link {
  text-decoration: underline;
  color: #9747ff;
  cursor: pointer;
}

.bulletList {
  list-style-position: inside;

  li + li {
    margin-top: 12px;
  }
}
