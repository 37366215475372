@use '../components/shared/global.style.module.scss' as g;

.notFoundContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 80px;
  height: calc(100vh - var(--spacing-header-small));

  @include g.responsive(g.$LARGE) {
    margin-top: 144px;
    height: calc(100vh - var(--spacing-header-big));
  }
}

.notFoundImage {
  width: 100%;
  height: auto;
  max-width: 500px;
  margin-bottom: 80px;

  @include g.responsive(g.$MEDIUM) {
    width: auto;
  }
}

.notfoundButton {
  @include g.buttonRegular;
  line-height: 22px;
  padding: 8px 8px 8px 16px;

  @include g.responsive(g.$MEDIUM) {
    font-size: var(--font-size-button-large);
    font-weight: var(--font-weight-button-large);
    line-height: var(--line-height-button-large);
    padding: 16px 12px 16px 24px;
  }
}

.notfoundButtonImage {
  display: none;

  @include g.responsive(g.$LARGE) {
    display: flex;
  }
}

.notFoundTitle {
  @include g.h4;
  color: var(--white-primary);
  margin-bottom: 16px;

  @include g.responsive(g.$MEDIUM) {
    @include g.h3;
  }
}

.notFoundSubTitle {
  @include g.bodyRegular;
  color: var(--white-primary);
  margin-bottom: 32px;

  @include g.responsive(g.$MEDIUM) {
    @include g.bodyLarge;
  }
}
