@use '../../styles/Background.module.scss' as bg;
@use '../../components/shared/global.style.module.scss' as g;

$PAGE_BACKGROUND_ANIMATION_DURATION: 30s;

@keyframes backgroundTopAnimation {
  from {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(100vw, 0);
  }

  50% {
    transform: translate(100vw, 100vh);
  }

  75% {
    transform: translate(0, 100vh);
  }

  to {
    transform: translate(0, 0);
  }
}

@keyframes backgroundBottomAnimation {
  from {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(-100vw, 0);
  }

  50% {
    transform: translate(-100vw, -100vh);
  }

  75% {
    transform: translate(0, -100vh);
  }

  to {
    transform: translate(0, 0);
  }
}

.pageBackdrop {
  background: var(--background);
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  position: relative;
  z-index: 1;
  overflow: hidden;

  &.dark {
    background: var(--background-dark);
  }

  &:before,
  &:after {
    @include bg.background-gradient-style('light');
    animation-duration: $PAGE_BACKGROUND_ANIMATION_DURATION;
    animation-timing-function: linear;
    pointer-events: none;
  }

  &.dark:before,
  &.dark:after {
    @include bg.background-gradient-style('dark');
  }

  &:before {
    animation-name: backgroundTopAnimation;
    @include bg.background-gradient-style-responsive('before');
  }

  &:after {
    animation-name: backgroundBottomAnimation;
    @include bg.background-gradient-style-responsive('after');
  }
}

.pageOverlay {
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scrollContainer {
  overflow-y: auto;
  position: relative;
  transition: 0.5s;
  height: 100vh;
  @include g.responsive(g.$MEDIUM) {
    height: calc(100vh - var(--spacing-header-small));
  }
  @include g.responsive(g.$LARGE) {
    height: calc(100vh - var(--spacing-header-big));
  }
}

.pageContainer {
  background: transparent;
  z-index: 1;
  @include g.fullContainer;

  :global(.banner) {
    & + :global(.banner) {
      @include g.responsiveSectionSpacingInverse;
    }
  }
}
