@use '../../../../components/shared/global.style.module.scss' as g;

.container {
  margin-top: var(--spacing-section-small);
  @include g.responsive(g.$LARGE) {
    margin-top: var(--spacing-section-medium);
  }
}

.imageContainer {
  width: 100%;
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

.caption {
  text-align: center;
  margin: 6px 0;
}
