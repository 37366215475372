@use '../../shared/global.style.module.scss' as g;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 32px 0;
  width: 100%;

  @include g.responsive(g.$MEDIUM) {
    margin: 80px 0 32px 0;
    gap: 36px;
  }

  @include g.responsive(g.$LARGE) {
    margin: 80px 0;
    width: 789px;
  }
}

.blogTagText {
  color: var(--Purple-Primary, #9747ff);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.5px;
}

.blogTitleText {
  color: var(--Neutral-800, #312f33);
  @include g.h4;

  @include g.responsive(g.$MEDIUM) {
    @include g.h2;
  }
}

.blogSubTitleText {
  color: #000000;
  @include g.bodyRegular;

  @include g.responsive(g.$MEDIUM) {
    @include g.bodyLarge;
  }
}

.authorImage {
  object-fit: cover;
  border-radius: 64px;
  width: 64px;
  height: 64px;

  @include g.responsive(g.$MEDIUM) {
    border-radius: 80px;
    width: 80px;
    height: 80px;
  }
}

.authorFooterImage {
  object-fit: cover;
  border-radius: 64px;
  width: 125px;
  height: auto;

  @include g.responsive(g.$X_LARGE) {
    border-radius: 80px;
    width: 150px;
  }
}

.authorName {
  color: #000000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: -0.5px;

  @include g.responsive(g.$MEDIUM) {
    @include g.h5;
  }
}

.authorTitle {
  color: #000000;
  @include g.bodyRegular;

  @include g.responsive(g.$MEDIUM) {
    @include g.bodyLarge;
  }
}

.authorContainer {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;

  @include g.responsive(g.$MEDIUM) {
    gap: 24px;
  }
}

.timeEstimate {
  /* Body Large */
  font-size: var(--font-size-body-regular);
  font-style: normal;
  font-weight: var(--font-weight-body-regular);
  line-height: var(--line-height-body-regular);
  letter-spacing: -0.5px;
  display: flex;
  height: min-content;
  white-space: nowrap;

  @include g.responsive(g.$MEDIUM) {
    font-size: var(--font-size-body-large);
    font-weight: var(--font-weight-body-large);
    line-height: var(--line-height-body-large);
  }

  @include g.responsive(g.$LARGE) {
    margin-left: auto;
  }
}
.authorAndTime {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include g.responsive(g.$LARGE) {
    gap: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.coverImage {
  display: flex;
  width: 100vw;
  height: 340px;
  align-items: center;
  position: relative;
  object-fit: cover;
  object-position: left;

  @include g.responsive(g.$X_LARGE) {
    height: 470px;
  }
}

.coverImageContainer {
  width: 100vw;
  max-height: 600px;
  min-height: 300px;
  overflow: hidden; /* Ensure the image doesn't overflow */
  position: relative;
}

.blogContent {
  @include g.typographySpacingInsights();

  & {
    margin: 32px 0;
    width: 100%;

    @include g.responsive(g.$LARGE) {
      width: 789px;
      margin: 80px 0;
    }
  }
}

.summary {
  ul {
    margin-bottom: var(--spacing-section-small);
    @include g.responsive(g.$LARGE) {
      margin-bottom: var(--spacing-section-medium);
    }
  }
}

.authorNameFooter {
  color: var(--Purple-dark, #400084);
  @include g.h6;

  @include g.responsive(g.$MEDIUM) {
    @include g.h5;
  }
}

.authorTitleFooter {
  color: var(--Neutral-700, #706d73);
  @include g.bodySmall;

  @include g.responsive(g.$MEDIUM) {
    @include g.bodyRegular;
  }
}

.authorBoiFooter {
  color: #000000;
  @include g.bodySmall;

  @include g.responsive(g.$MEDIUM) {
    @include g.bodyRegular;
  }
}

.authorContainerFooter {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  margin-bottom: var(--spacing-section-small);

  @include g.responsive(g.$MEDIUM) {
    margin-bottom: var(--spacing-section-medium);
  }
  @include g.responsive(g.$LARGE) {
    width: 789px;
  }
}

.moreContainer {
  background: #312f33;
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
}

.moreInnerContainer {
  padding: var(--spacing-section-medium, 80px) 16px;
  width: 100%;

  @include g.responsive(g.$MEDIUM) {
    padding: var(--spacing-section-medium, 80px) 48px;
  }

  @include g.responsive(g.$LARGE) {
    padding: var(--spacing-section-medium, 80px) 0;
    width: var(--container-width-large);
  }

  @include g.responsive(g.$X_LARGE) {
    width: var(--container-width-xl);
  }
}

.moreFlexContainer {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  overflow-x: auto;
  margin: 0 -16px;
  padding: 0 16px;
  scrollbar-width: none;

  @include g.responsive(g.$LARGE) {
    margin: 0 -48px;
    padding: 0 48px;
  }

  @include g.responsive(g.$LARGE) {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  @include g.responsive(g.$X_LARGE) {
    gap: 48px;
  }
}
.moreH2 {
  color: var(--white-primary) !important;
  margin-bottom: var(--spacing-48, 48px);
}

.avatarImage {
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 50%;

  @include g.responsive(g.$MEDIUM) {
    width: 80px;
    height: 80px;
  }
}

.featuredInsightsContainer {
  display: flex;
  align-items: center;
}
