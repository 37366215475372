@use '../components/shared/global.style.module.scss' as g;

@mixin background-gradient-style($theme) {
  content: '';
  position: fixed;
  z-index: -100;
  background: if($theme == 'dark', var(--background-gradient-dark), var(--background-gradient));
  filter: blur(2px);
}

@mixin background-gradient-style-responsive($position) {
  $top-bottom: if($position == 'before', top, bottom);
  $left-right: if($position == 'before', left, right);

  width: var(--background-gradient-size-small);
  height: var(--background-gradient-size-small);
  border-radius: var(--background-gradient-size-small);
  #{$top-bottom}: calc(-1 * var(--background-gradient-size-small) / 2);
  #{$left-right}: calc(-1 * var(--background-gradient-size-small) / 2);

  @include g.responsive(g.$MEDIUM) {
    width: var(--background-gradient-size-medium);
    height: var(--background-gradient-size-medium);
    border-radius: var(--background-gradient-size-medium);
    #{$top-bottom}: calc(-1 * var(--background-gradient-size-medium) / 2);
    #{$left-right}: calc(-1 * var(--background-gradient-size-medium) / 2);
  }

  @include g.responsive(g.$LARGE) {
    width: var(--background-gradient-size-large);
    height: var(--background-gradient-size-large);
    border-radius: var(--background-gradient-size-large);
    #{$top-bottom}: calc(-1 * var(--background-gradient-size-large) / 2);
    #{$left-right}: calc(-1 * var(--background-gradient-size-large) / 2);
  }
}
