@use '../shared/global.style.module.scss' as g;

@mixin gradient-background {
  background: linear-gradient(265deg, #9747ff -16.79%, #7a98ff 118.81%);
}

.regular {
  font-size: var(--font-size-button-regular) !important;
  font-weight: var(--font-weight-button-regular) !important;
  line-height: var(--line-height-button-regular) !important;
  letter-spacing: var(--letter-spacing) !important;
}
.large {
  font-size: var(--font-size-button-large) !important;
  font-weight: var(--font-weight-button-large) !important;
  line-height: var(--line-height-button-large) !important;
  letter-spacing: var(--letter-spacing) !important;
}

.styledButton {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: var(--spacing-8);
  border-radius: 99px;
  background: transparent;
  cursor: pointer;
  font-size: var(--font-size-button-regular);
  font-weight: var(--font-weight-button-regular);
  line-height: 22px;
  letter-spacing: var(--letter-spacing);

  @include g.responsive(g.$LARGE) {
    font-size: var(--font-size-button-large);
    font-weight: var(--font-weight-button-large);
    line-height: var(--line-height-button-large);
  }

  &:disabled {
    cursor: not-allowed;
    background: var(--Text-Button-Disabled-BG, #706d73) !important;
    color: var(--Text-Button-Disabled-text, #fbfafc) !important;
  }

  &.dark {
    color: var(--white-primary);
  }

  &.light {
    color: var(--text-button-default);
  }

  &.primary {
    border: none;
    padding: 8px 16px;

    &.active {
      color: var(--purple-primary);

      svg {
        fill: var(--purple-primary);
      }
    }

    &:hover {
      color: var(--purple-primary);

      svg {
        fill: var(--purple-primary);
      }
    }
  }

  &.secondary {
    border: none;
    background: var(--light-button);
    padding: 8px 16px;

    @include g.responsive(g.$LARGE) {
      padding: 16px 24px;
    }

    &:hover {
      color: var(--white-primary);
      @include gradient-background;
    }

    &.complete {
      background: var(--Primary-Purple, #9747ff);
    }
  }

  &.outlined {
    padding: 8px 16px;
    line-height: 27px;

    @include g.responsive(g.$LARGE) {
      padding: 16px 24px;
    }

    &.dark {
      border: 1px solid var(--white-primary);
    }

    &.light {
      border: 1px solid var(--text-button-default);
    }

    &.active {
      border: 1px solid transparent;
      color: var(--white-primary);
      @include gradient-background;
    }

    &:hover {
      border: 1px solid var(--purple-primary);
      color: var(--white-primary);
      @include gradient-background;
    }
  }
}

.styledButtonLink {
  width: fit-content;
  border-radius: 100px;
  padding: 8px 16px;
  background: transparent;
  text-decoration: none;
  align-items: center;
  display: flex;
  gap: var(--spacing-8);
  font-size: var(--font-size-button-regular);
  font-weight: var(--font-weight-button-regular);
  line-height: 22px;
  letter-spacing: var(--letter-spacing);

  @include g.responsive(g.$LARGE) {
    padding: 16px 24px;
    font-size: var(--font-size-button-large);
    font-weight: var(--font-weight-button-large);
    line-height: var(--line-height-button-large);
  }

  &.dark {
    color: var(--white-primary);
  }

  &.light {
    color: var(--text-button-default);
  }

  &.primary {
    padding: 0;
    &:hover {
      color: var(--text-button-hover);
      svg {
        fill: var(--text-button-hover);
      }
    }
  }

  &.secondary {
    border: none;
    background: var(--light-button);

    &.dark {
      color: var(--text-body);
    }

    &.light {
      color: var(--white-primary);
    }

    &:hover {
      @include gradient-background;

      svg {
        fill: var(--white-primary);
      }

      &.dark {
        color: var(--white-primary);
      }

      &.light {
        color: var(--text-body);
      }
    }
  }

  &.outlined {
    border: solid 1px var(--text-button-default);
    line-height: 20px;

    @include g.responsive(g.$LARGE) {
      line-height: 27px;
    }

    &.dark {
      border-color: var(--white-primary);
    }

    &.light {
      border-color: var(--text-button-default);
    }

    &:hover {
      color: var(--white-primary);
      border: solid 1px #1a0e2f99;
      @include gradient-background;

      &.light {
        border-color: transparent !important;
      }

      svg {
        fill: var(--white-primary);
      }
    }
  }
}
