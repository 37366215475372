@use '../shared/global.style.module.scss' as g;

.menuContainer {
  margin-top: 32px;
  gap: 24px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @include g.responsive(g.$LARGE) {
    flex-direction: column;
  }
}
.galleryContainer {
  display: grid;
  grid-row-gap: 80px;
  grid-column-gap: 32px;
  grid-template-columns: repeat(auto-fit, minmax(calc(100%), 1fr));

  @include g.responsive(g.$X_LARGE) {
    grid-template-columns: repeat(auto-fit, minmax(calc(50% - 16px), 1fr));
  }
}

.galleryItem {
  position: relative;

  h4 {
    color: var(--purple-light, #f2e5ff) !important;
    margin: 16px 0;
  }

  min-width: 294px;
  @include g.responsive(g.$LARGE) {
    min-width: auto;
  }

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: var(--Spacing-16, 16px);
    overflow: hidden;
    height: 180px;
    min-height: 180px;
    max-height: 180px;

    @include g.responsive(g.$X_LARGE) {
      height: 240px;
      min-height: 240px;
      max-height: 240px;
    }
  }
}

.moreGalleryItem {
  position: relative;

  h4 {
    color: var(--purple-light, #f2e5ff) !important;
    margin: 16px 0;
  }

  min-width: 294px;
  width: calc(calc(100% - 64px) / 3);
  @include g.responsive(g.$LARGE) {
    min-width: auto;
  }

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: var(--Spacing-16, 16px);
    overflow: hidden;
    height: 180px;
    min-height: 180px;
    max-height: 180px;

    @include g.responsive(g.$X_LARGE) {
      height: 240px;
      min-height: 240px;
      max-height: 240px;
    }
  }

  @include g.responsive(g.$X_LARGE) {
    width: calc(calc(100% - 96px) / 3);
  }
}

.blogLink {
  border-width: 0 !important;
  padding: 12px 0 !important;
  @include g.outlinedToggleButtonDark;

  @include g.responsive(g.$MEDIUM) {
    border-width: 1px !important;
    padding: 12px 16px !important;
  }
}

.insightHomepageContainer {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: var(--spacing-section-medium);

  @include g.responsiveSectionSpacing;
  @include g.responsive(g.$LARGE) {
    flex-direction: row;
    gap: 32px;
  }
}

.leftTopContainer {
  width: 100%;
}

.rightBottomContainer {
  width: 100%;

  @include g.responsive(g.$LARGE) {
    width: 621px;
    min-width: 621px;
  }

  @include g.responsive(g.$X_LARGE) {
    width: 789px;
    min-width: 789px;
  }
}

.galleryItemTitle {
  color: var(--white-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  align-self: stretch;
  margin: 24px 0 12px 0;
  @include g.h5;

  @include g.responsive(g.$MEDIUM) {
    min-height: 72px;
    -webkit-line-clamp: 2;
  }

  @include g.responsive(g.$X_LARGE) {
    min-height: 108px;
    -webkit-line-clamp: 3;
  }
}

.galleryItemSubTitle {
  color: var(--white-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  align-self: stretch;
  margin: 0 0 24px 0;
  @include g.bodyRegular;

  @include g.responsive(g.$LARGE) {
    margin: 0;
  }

  @include g.responsive(g.$X_LARGE) {
    min-height: 135px;
    -webkit-line-clamp: 5;
  }
}

.moreGalleryItemSubTitle {
  color: var(--white-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  align-self: stretch;
  @include g.bodyRegular;

  @include g.responsive(g.$LARGE) {
    margin: 0;
  }

  @include g.responsive(g.$X_LARGE) {
    min-height: 135px;
    -webkit-line-clamp: 5;
  }
}

.galleryItemTime {
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--white-primary);
  margin: 24px 0 0 0;
  @include g.bodyRegular;

  @include g.responsive(g.$LARGE) {
    margin: 24px 0 24px 0;
  }

  p {
    @include g.bodyRegular;
  }
}

.moreGalleryItemTime {
  width: auto;
  display: flex;
  color: var(--white-primary);
  margin: 24px 0 24px 0;

  p {
    @include g.bodyRegular;
  }
}

.galleryTextBlock {
  display: flex;
  flex-direction: column-reverse;

  @include g.responsive(g.$LARGE) {
    flex-direction: column;
  }
}

.moreGalleryTextBlock {
  display: flex;
  flex-direction: column;
}

.galleryChip {
  display: none;
  position: absolute;
  top: 20px;
  left: 20px;
  border-radius: 8px;
  padding: 12px 24px;
  background: var(--Primary-Purple, #9747ff);
  color: var(--white-primary);
  @include g.buttonRegular;

  @include g.responsive(g.$MEDIUM) {
    display: block;
  }
}

.tagChip {
  @include g.h6;
  display: block;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  width: fit-content;
  color: #ffffff;
  padding: 12px 24px;
  border-radius: var(--spacing-48, 48px);
  border: 1px solid #ffffff;
  box-shadow: 4px 4px 40px 0 rgba(0, 0, 0, 0.05);
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: -0.5px;
  cursor: pointer;
  height: 51px;
  background: transparent;

  &:hover {
    background: transparent;
    color: var(--purple-primary, #9747ff);
    border: 1px solid var(--purple-primary, #9747ff);
  }
}

.activeTagChip {
  font-weight: 600;
  border: 1px solid var(--purple-primary, #9747ff) !important;
  color: var(--neutral-100) !important;
  background: var(--purple-primary, #9747ff) !important;
}

.featuredInsightsContainer {
  display: flex;
  align-items: center;
}
